<template>
  <div class="bg-gray-50 h-screen">
    <div class="p-4 pt-12 container max-w-screen-lg mx-auto">
      <section>
        <div class="mb-10 grid grid-cols-2">
          <span class="text-xl mb-6 uppercase tracking-wide"> berita </span>
          <div class="text-right pr-3">
            <!-- trigger add new news -->
            <button class="button --primary ml-4" @click="SHOW_FORM()">
              <fa-icon icon="plus"></fa-icon>
              <span class="ml-2">berita</span>
            </button>
          </div>
        </div>

        <!-- News List -->
        <p v-if="!hasNews" class="p-2 uppercase tracking-wide text-center">
          Belum ada berita
        </p>
        <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
          <item-card
            class="mb-4 max-w-xs mx-auto bg-white"
            :is-active="true"
            v-for="(item, key) in news"
            :key="key"
          >
            <!-- Menu -->
            <template #menu>
              <button class="button" @click="showForm(item)">
                <span>Edit</span>
                <fa-icon icon="pencil-alt"></fa-icon>
              </button>
              <button class="button" @click="triggerDeleteNews(item)">
                <span>Hapus</span>
                <fa-icon icon="trash-alt"></fa-icon>
              </button>
            </template>

            <!-- Contents -->
            <section class="">
              <p
                :title="item.title"
                class="capitalize tracking-wide text-lg font-bold"
              >
                {{
                  item.title.length > 29
                    ? item.title.substring(0, 26) + "..."
                    : item.title
                }}
              </p>
              <p class="subtle-label mb-2">
                EDIT TERAKHIR {{ item.updated_at | indonesian_datetime_format }}
              </p>
              <img
                :src="item.thumbnail_url_full"
                alt="Belum ada foto"
                :id="`news-image-${item.id}`"
                class="shadow rounded min-h-full mb-4"
                style="min-height: 150px"
                :class="{ 'p-2': item.thumbnail_url_full === null }"
              />

              <div class="text-right">
                <span
                  v-if="item.is_published"
                  class="text-gray-700 inline-block mx-1 text-xs bg-gray-100 p-2 px-4 rounded-full"
                >
                  <fa-icon icon="check"></fa-icon>
                  <span class="ml-1 capitalize tracking-wider">
                    publikasi
                  </span>
                </span>
                <span
                  v-if="item.is_highlighted"
                  class="inline-block text-xs text-green-900 mx-1 bg-green-200 p-2 px-4 rounded-full"
                >
                  <fa-icon icon="medal"></fa-icon>
                  <span class="ml-1 capitalize tracking-wider">
                    program unggulan
                  </span>
                </span>
              </div>
            </section>
          </item-card>
        </div>
      </section>

      <news-editor></news-editor>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-profile-container {
  @apply p-4 shadow mb-8 rounded bg-gray-100 mt-36;
  @apply max-w-sm;
}
</style>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import _clone from "lodash/clone";

export default {
  methods: {
    hideMenu() {
      EventBus.$emit("item-card.menu.hide");
    },
    showForm(news) {
      this.SHOW_FORM();
      this.SET_SELECTED_NEWS(news);
      this.form = _clone(this.selected_news);
      this.hideMenu();
    },

    async triggerDeleteNews(news) {
      await this.deleteNews(news);
    },

    ...mapActions("news", ["fetchNews", "deleteNews"]),
    ...mapMutations("news", ["SHOW_FORM", "SET_SELECTED_NEWS"]),
  },
  computed: {
    hasNews() {
      return this.news.length > 0;
    },

    ...mapGetters("news", ["news"]),
  },
  mounted() {
    this.fetchNews();
  },

  components: {
    ItemCard: () => import("@/components/Common/ItemCard.vue"),
    NewsEditor: () => import("@/components/News/NewsEditor.vue"),
  },
  name: "ManagementNews",
};
</script>